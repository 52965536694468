import React, { useEffect, createRef, useRef, DetailedHTMLProps, HtmlHTMLAttributes, HTMLAttributes, useLayoutEffect, useState } from "react";
import * as THREE from 'three';
import { Canvas, useFrame } from "@react-three/fiber";
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { Work } from '../work';
import { OpenWork } from '../openwork';
import { TimelineMax } from "gsap";
import { TweenMax } from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons";
import gsap from "gsap/all";

const globalStyles = css`
  ${emotionReset}
`;

const OpeningScene = (props) => {
  
  const timeline = new TimelineMax({paused: false});
  const tl2 = new TimelineMax({paused: false});
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const bd0 = useRef();
  const bd1 = useRef();
  const bd2 = useRef();

  const point0 = useRef();

  useLayoutEffect(() => {
    timeline.add(
      TweenMax.to(ref1.current, 1, {position: 'absolute', top: 50 + '%', left: 50 + '%', transform: 'translateY(' + -50 +'%) translateX(' + -50 + '%)', autoAlpha: 1, ease: "easeOut" }))
      .to(ref1.current, 1, {yoyo: true, repeat: -1, top: 52 + '%', left: 50 + '%', transform: 'translateY(' + -50 +'%) translateX(' + -50 + '%)', ease: "Power1.easeInOut"})
      .play();

    TweenMax.to(point0.current, 0.7, {delay: 1, yoyo: true, repeat: -1, opacity: 1, ease: "easeIn"});
  }, []);

  const openCurtain = () => {
    gsap.killTweensOf('#point0');
    TweenMax.to(point0.current, 0.5, {opacity: 0, ease: "easeOut"});

    tl2.add(
      TweenMax
      .to(ref1.current, 0.5, {width: 19 + 'vh', height: 19 + 'vh', transform: 'translateY(' + -51 +'%) translateX(' + -51 + '%)', autoAlpha: 0, ease: "easeIn"}))
      .to(bd0.current, 0.6, {y: -30, opacity: 1, autoAlpha: 1, ease: "Back.easeOut"})
      .to(bd1.current, 0.6, {y: -30, opacity: 1, autoAlpha: 1, ease: "Back.easeOut"})
      .to(bd2.current, 1, {y: -30, opacity: 1, autoAlpha: 1, ease: "Back.easeOut"})
      .to(ref3.current, 0.7, {top: -100 + 'vh', autoAlpha: 0.7, ease: "Back.easeIn"})
      .play();
  };

  return (
    <div ref={ref3} style={{position: "absolute", top: 0, left: 0, zIndex: 10, width: 100 + 'vw', height: 100 + 'vh', backgroundColor: 'rgba(255, 255, 255, 1)'}}>
      <Global styles={globalStyles} />
      <div id="openingh1" ref={ref2} >
        <div ref={bd0} style={{opacity: 0, marginBottom: 2 + 'vw'}}><span style={{fontSize: 150 + '%'}}>H</span>appy <span style={{fontSize: 120 + '%'}}>B</span>irthday</div>
        <div ref={bd1} style={{opacity: 0, marginBottom: 2 + 'vw'}}>dear</div>
        <div ref={bd2} style={{opacity: 0}}><span style={{fontSize: 150 + '%'}}>AI</span>-chan!!</div>
      </div>
      <img ref={ref1} src="./giftbox.svg" style={{cursor: 'pointer', opacity: 0, width: 20 + 'vh', height: 20 + 'vh', position: 'absolute', top: 100 + '%', left: 50 + '%', transform: 'translateY(' + -50 +'%) translateX(' + -50 + '%)'}} onClick={() => {openCurtain()}} />
      <div id="point0" ref={point0} style={{opacity: 0, position: 'absolute', top: 70 + '%', left: 50 + '%', transform: 'translateY(' + -50 +'%) translateX(' + -50 + '%)'}}>
        <FontAwesomeIcon icon={faHandPointer} style={{fontSize: 20 + 'px', color: "silver"}}/>
      </div>
    </div>
  )
}
export default OpeningScene
