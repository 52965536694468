import React, { useState } from "react";
import Modal from "react-modal";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import BaseScene from '../scenes/BaseScene';
import OpeningScene from '../scenes/OpeningScene';
import Petal from "../components/petal";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faListAlt, faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const IndexPage = ({data}) => {
  const [petalIsOpen, setPetalOpen] = useState(false);
  const [mesNum, setMesNum] = useState(0);
  const [isExpanded, setExpanded] = useState(true);

  const changeState = (isState) => {
    setMesNum(isState);
    if(!petalIsOpen) {
      setPetalOpen(true);
    }
  }

  const changePetalOpen = () => {
    setPetalOpen(false);
  }

  const[openAllowed, setOpenAllowed] = useState(false);
  const[modalOpen, setModalOpen] = useState(false);

  // openをallowする関数をworkに渡し、denyする関数をpetalに渡す
  const allowOpen = () => {
    setOpenAllowed(true);
    setExpanded(false);
  };

  const denyOpen = () => {
    setOpenAllowed(false);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  return(
  <Layout>
    <OpeningScene />
    <BaseScene changeState={changeState} allowOpen={allowOpen} allowed={openAllowed} />
    <motion.h1 id="mainh1" animate={{x: isExpanded ? 0 : 1000, opacity: isExpanded ? 1 : 0}} style={{fontFamily: 'Bad Script', fontSize: 'calc(' + 16 + 'px + ' + 3 + 'vw)'}}><span style={{fontSize: 150 + '%'}}>H</span>appy <span style={{fontSize: 120 + '%'}}>B</span>irthday dear <span style={{fontSize: 150 + '%'}}>AI</span>-chan!!</motion.h1>
    <motion.div className={"topMes"} animate={{x: isExpanded ? 0 : 800, opacity: isExpanded ? 1 : 0}}>
      <p>キズナアイちゃん 5歳のお誕生日、そして「<a href="https://5th.kizunaai.com/ai-party2021/" target="_blank">A.I.Party 2021</a>」開催、本当におめでとう！</p>
      <p>お祝いの気持ちを込めて、バーチャルフラワースタンドをお贈りします。</p>
      <p>フラスタの“ぴょこぴょこ花”には、参加者の皆さんからのメッセージが寄せられています。<br />ぜひ <FontAwesomeIcon icon={faMousePointer} style={{color: "hotpink"}}/><span style={{borderBottom: 1 + 'px solid hotpink'}}>お手に取って</span>ご覧ください。</p>
      <p>アイちゃんの一層のご活躍を心より応援しています。</p>
      <p style={{textAlign: "right"}}><span style={{fontFamily: 'Bad Script', fontSize: 150 + '%'}}>A. I. Flower!! 2021 Project</span> 一同       <Button onClick={() => setModalOpen(true)}>
        <FontAwesomeIcon icon={faListAlt} />
      </Button>
      </p>

    </motion.div>
    
    <motion.div className={"arrowParent"} onClick={() => {if(!isExpanded) {denyOpen(); setPetalOpen(false); }  setExpanded(!isExpanded);}}>
      <motion.div className={"arrow"} animate={{x: isExpanded ? 0 : 100, opacity: isExpanded ? 0.4 : 0, rotateZ: isExpanded ? 0 : 180, display: isExpanded ? 'block' : 'none'}} />
      <motion.div className={"arrow"} animate={{x: isExpanded ? 0 : 80, opacity: isExpanded ? 0.7 : 0, rotateZ: isExpanded ? 0 : 180, display: isExpanded ? 'block' : 'none'}} />
      <motion.div className={"arrow"} animate={{rotateZ: isExpanded ? 0 : 180}} />
    </motion.div>
    <motion.section id="petalParent" animate={{x: petalIsOpen ? 0 : -2000, y: petalIsOpen ? 0 : 1000}}>
      <Petal mesData={data.allMessagesJson.edges[mesNum]} changePetalOpen={changePetalOpen} denyOpen={denyOpen} />
    </motion.section>

    <Modal
      isOpen={modalOpen}
      onRequestClose={() => handleCloseModal()}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before"
      }}
      closeTimeoutMS={300}
    >
      <div className={"modalTitle"}>A. I. Flower!! 2021 Project</div>
      <div className={"modalParent"}>
      {data.allMessagesJson.edges.map((each) =>{
        return ( 
          <div className={"modalMes"}>
            <p style={{fontSize: 140 + '%', marginBottom: 0.5 + 'em'}}>{each.node.name} {each.node.twitter.length > 0 && <a href={each.node.twitter} target="_blank"><FontAwesomeIcon icon={faTwitter} style={{color: 'rgb(29, 161, 242)'}} /></a> }</p>
            <p>{each.node.message.split('\n').map((str, index) => (<React.Fragment key={index}>{str}<br /></React.Fragment>))}</p>
            {each.node.youtube.length > 0 &&
                    <div className="koresuki">
                        <p><FontAwesomeIcon icon={faYoutube} style={{color: 'rgb(255, 0, 0)'}} /> <a href={each.node.youtube} target="_blank">{each.node.title}</a></p>
                        <p dangerouslySetInnerHTML={{__html : each.node.comment}} />
                    </div>
            }
          </div>
        )
      })}
      </div>
    </Modal>

  </Layout>
)};

export const query = graphql`
  query {
    allMessagesJson {
      edges {
        node {
          message
          name
          twitter
          youtube
          title
          comment
        }
      }
    }
  }
`;

export default IndexPage