/** @jsx jsx */
import { Suspense, useMemo, useRef, useState, useEffect } from 'react';
import { Canvas, extend, useFrame } from 'react-three-fiber';
import { DefaultXRControllers, XR } from 'react-xr';
import { css, jsx } from '@emotion/react';
import * as THREE from 'three';
import Controls from './utils/Controls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { useGLTF } from '@react-three/drei/core/useGLTF';
import { useSpring, animated } from '@react-spring/three';
import { MeshLine, MeshLineMaterial } from 'threejs-meshline';
import { VRButton } from 'three-stdlib';

extend({ EffectComposer, RenderPass, OutlinePass, ShaderPass, MeshLine, MeshLineMaterial })

const theme = css`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,208,254,1) 90%, rgba(255,183,240,1) 100%);
    background: conic-gradient(at 50% 120%, rgba(255,93,192,0.3), rgba(252,188,104,0.3) 85deg, rgba(165,190,255,0.3) 275deg, rgba(255,93,192,0.3));
`;

const Plane = () => (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} receiveShadow >
        <gridHelper position={[0, 0, 0.01]} rotation={[Math.PI / 2, 0, 0]} args={[2000, 2000, "gray", "gray"]}/>
        <planeBufferGeometry attach="geometry" args={[2000, 2000]} />
        <meshPhysicalMaterial attach="material" color="white" />
    </mesh>
);

function Model(props) {

    const group = useRef();
    const { nodes, materials } = useGLTF('/rwbox06302.glb');

    // index.tsxにあるstateを切り替える
    const changeState = props.changeState;

    const [isOpen0, setOpen0] = useState(false);
    const [isOpen1, setOpen1] = useState(false);
    const [isOpen2, setOpen2] = useState(false);
    const [isOpen3, setOpen3] = useState(false);
    const [isOpen4, setOpen4] = useState(false);
    const [isOpen5, setOpen5] = useState(false);
    const [isOpen6, setOpen6] = useState(false);
    const [isOpen7, setOpen7] = useState(false);
    const [isOpen8, setOpen8] = useState(false);
    const [isOpen9, setOpen9] = useState(false);
    const [isOpen10, setOpen10] = useState(false);
    const [isOpen11, setOpen11] = useState(false);
    const [isOpen12, setOpen12] = useState(false);
    const [isOpen13, setOpen13] = useState(false);
    const [isOpen14, setOpen14] = useState(false);
    const [isOpen15, setOpen15] = useState(false);
    const [isOpen16, setOpen16] = useState(false);
    const [isOpen17, setOpen17] = useState(false);
    const [isOpen18, setOpen18] = useState(false);
    const [isOpen19, setOpen19] = useState(false);
    const [isOpen20, setOpen20] = useState(false);
    const [isOpen21, setOpen21] = useState(false);
    const [isOpen22, setOpen22] = useState(false);
    const [isOpen23, setOpen23] = useState(false);
    const [isOpen24, setOpen24] = useState(false);

    const allowOpen = props.allowOpen;

    // petal0
    const {pos0, rot0, scale0, ...props0} = useSpring({
      pos0: isOpen0 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot0: isOpen0 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale0: isOpen0 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });  
    
    // petal1
    const {pos1, rot1, scale1, ...props1} = useSpring({
      pos1: isOpen1 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot1: isOpen1 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale1: isOpen1 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal2
    const {pos2, rot2, scale2, ...props2} = useSpring({
      pos2: isOpen2 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot2: isOpen2 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale2: isOpen2 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal3
    const {pos3, rot3, scale3, ...props3} = useSpring({
      pos3: isOpen3 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot3: isOpen3 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale3: isOpen3 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal4
    const {pos4, rot4, scale4, ...props4} = useSpring({
      pos4: isOpen4 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot4: isOpen4 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale4: isOpen4 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal5
    const {pos5, rot5, scale5, ...props5} = useSpring({
      pos5: isOpen5 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot5: isOpen5 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale5: isOpen5 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal6
    const {pos6, rot6, scale6, ...props6} = useSpring({
      pos6: isOpen6 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot6: isOpen6 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale6: isOpen6 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal7
    const {pos7, rot7, scale7, ...props7} = useSpring({
      pos7: isOpen7 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot7: isOpen7 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale7: isOpen7 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal8
    const {pos8, rot8, scale8, ...props8} = useSpring({
      pos8: isOpen8 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot8: isOpen8 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale8: isOpen8 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal9
    const {pos9, rot9, scale9, ...props9} = useSpring({
      pos9: isOpen9 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot9: isOpen9 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale9: isOpen9 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal10
    const {pos10, rot10, scale10, ...props10} = useSpring({
      pos10: isOpen10 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot10: isOpen10 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale10: isOpen10 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal11
    const {pos11, rot11, scale11, ...props11} = useSpring({
      pos11: isOpen11 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot11: isOpen11 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale11: isOpen11 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal12
    const {pos12, rot12, scale12, ...props12} = useSpring({
      pos12: isOpen12 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot12: isOpen12 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale12: isOpen12 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal13
    const {pos13, rot13, scale13, ...props13} = useSpring({
      pos13: isOpen13 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot13: isOpen13 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale13: isOpen13 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal14
    const {pos14, rot14, scale14, ...props14} = useSpring({
      pos14: isOpen14 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot14: isOpen14 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale14: isOpen14 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal15
    const {pos15, rot15, scale15, ...props15} = useSpring({
      pos15: isOpen15 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot15: isOpen15 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale15: isOpen15 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal16
    const {pos16, rot16, scale16, ...props16} = useSpring({
      pos16: isOpen16 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot16: isOpen16 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale16: isOpen16 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal17
    const {pos17, rot17, scale17, ...props17} = useSpring({
      pos17: isOpen17 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot17: isOpen17 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale17: isOpen17 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal18
    const {pos18, rot18, scale18, ...props18} = useSpring({
      pos18: isOpen18 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot18: isOpen18 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale18: isOpen18 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal19
    const {pos19, rot19, scale19, ...props19} = useSpring({
      pos19: isOpen19 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot19: isOpen19 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale19: isOpen19 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal20
    const {pos20, rot20, scale20, ...props20} = useSpring({
      pos20: isOpen20 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot20: isOpen20 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale20: isOpen20 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal21
    const {pos21, rot21, scale21, ...props21} = useSpring({
      pos21: isOpen21 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot21: isOpen21 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale21: isOpen21 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal22
    const {pos22, rot22, scale22, ...props22} = useSpring({
      pos22: isOpen22 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot22: isOpen22 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale22: isOpen22 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal23
    const {pos23, rot23, scale23, ...props23} = useSpring({
      pos23: isOpen23 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot23: isOpen23 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale23: isOpen23 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    // petal24
    const {pos24, rot24, scale24, ...props24} = useSpring({
      pos24: isOpen24 ? [-0.79, 5, 0.73] : [-0.79, 2.94, 0.73],
      rot24: isOpen24 ? [0, 0, 0] : [2.05, -0.85, -2.67],
      scale24: isOpen24 ? [0, 0, 0] : [0.2, 0.2, 0.2],
      config: { mass: 1, tension: 200, friction: 12, precision: 0.001 }
    });

    //const allowed = props.allowed;

    const closeAll = () => {
      setOpen0(false);
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
      setOpen5(false);
      setOpen6(false);
      setOpen7(false);
      setOpen8(false);
      setOpen9(false);
      setOpen10(false);
      setOpen11(false);
      setOpen12(false);
      setOpen13(false);
      setOpen14(false);
      setOpen15(false);
      setOpen16(false);
      setOpen17(false);
      setOpen18(false);
      setOpen19(false);
      setOpen20(false);
      setOpen21(false);
      setOpen22(false);
      setOpen23(false);
      setOpen24(false);
    }

    useEffect(() => {
      if(!props.allowed) {
        closeAll();
      }
    });

    const[hovered, setHovered] = useState(false);

    useEffect(() => {
      document.body.style.cursor = hovered ? 'pointer' : 'auto';
    }, [hovered]);

    return (
      <group ref={group} {...props} dispose={null}>
      <group position={[-0.9, 0.31, 0.94]} rotation={[0, -1.17, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_1.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_1.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.54, 0.61, 0.7]} rotation={[0, -0.74, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_2.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_2.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.79, 0.9, 1.07]} rotation={[-0.29, -0.86, -0.22]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_3.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_3.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.24, 1.14, 0.63]} rotation={[0, -0.59, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_4.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_4.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.17, 1.19, 0.27]} rotation={[0, -0.12, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_5.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_5.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.43, 1.23, 0.26]} rotation={[-0.04, 0, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_6.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_6.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.67, 1.26, 1.03]} rotation={[-0.34, 0, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_7.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_7.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.16, 1.07, 0.78]} rotation={[-0.29, 0, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_8.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_8.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.44, 0.83, -0.04]} rotation={[-0.17, 0.54, 0.09]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_9.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_9.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.39, 1.17, 0.33]} rotation={[-0.09, 0, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_10.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_10.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.73, 1.21, 0.2]} rotation={[-0.2, 0.29, 0.06]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_11.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_11.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.85, 1.15, -0.23]} rotation={[0, 0.42, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_12.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_12.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.08, 1.05, -0.4]} rotation={[0, 0.47, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_13.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_13.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.13, 0.83, -0.24]} rotation={[0, 0.5, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_14.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_14.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.32, 0.56, 0.24]} rotation={[0, 0.13, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_15.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_15.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.11, 0.32, -0.11]} rotation={[0, 0.43, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_16.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_16.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.97, 0.08, -0.29]} rotation={[0, 0.53, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_17.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_17.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.74, -0.16, -0.38]} rotation={[0, 0.77, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_18.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_18.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.68, -0.5, 0.32]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_19.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_19.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.61, -0.48, -0.29]} rotation={[0, 0.49, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_20.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_20.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.18, -0.5, -0.09]} rotation={[0, 0.41, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_21.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_21.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.87, -0.48, 0.21]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_22.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_22.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.6, -0.48, 0.24]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_23.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_23.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.21, -0.47, 1.07]} rotation={[0, -0.96, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_24.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_24.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.55, -0.25, 0.98]} rotation={[0, -1.22, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_25.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_25.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.34, 0.22, 0.83]} rotation={[0, -0.81, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_26.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_26.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0, 0.65, 0.64]} rotation={[0, -0.39, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_27.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_27.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.05, 0.92, 0.75]} rotation={[0, -0.4, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_28.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_28.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.41, 0.9, 0.38]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_29.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_29.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.72, 0.98, 1.09]} rotation={[-0.23, -0.28, -0.06]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_30.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_30.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.05, 0.76, 0.75]} rotation={[-0.16, 0, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_31.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_31.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.22, 0.92, 0.03]} rotation={[0, 0.44, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_32.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_32.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.55, 0.94, 0.28]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_33.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_33.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.83, 0.91, 0.28]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_34.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_34.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2, 0.69, 0.05]} rotation={[0, 0.26, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_35.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_35.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.87, 0.43, -0.06]} rotation={[0, 0.53, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_36.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_36.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.72, 0.15, -0.05]} rotation={[0, 0.52, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_37.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_37.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.68, -0.1, 0.44]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_38.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_38.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.31, -0.2, 0.43]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_39.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_39.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.01, -0.22, 0.44]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_40.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_40.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.72, -0.2, 0.28]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_41.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_41.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.01, -0.19, 0.7]} rotation={[0, -0.5, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_42.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_42.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.1, 0.11, 0.76]} rotation={[0, -0.54, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_43.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_43.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.24, 0.44, 0.33]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_44.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_44.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.38, 0.63, 0.46]} rotation={[0, -0.12, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_45.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_45.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.83, 0.53, 0.47]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_46.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_46.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.24, 0.54, 0.49]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_47.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_47.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.52, 0.67, 0.11]} rotation={[0, 0.4, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_48.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_48.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.72, 0.69, 0.36]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_49.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_49.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.66, 0.4, 0.24]} rotation={[0, 0.27, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_50.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_50.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.52, 0.12, 0.42]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_51.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_51.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.21, 0.09, 0.5]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_52.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_52.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.82, 0.12, 0.43]} rotation={[0, -0.4, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_53.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_53.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.53, 0.12, 0.32]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_54.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_54.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.58, 0.38, 0.37]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_55.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_55.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.05, 0.36, 0.5]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_56.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_56.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.3, 0.32, 0.51]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_57.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_57.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.55, -0.01, 0.81]} rotation={[0, -0.95, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_58.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_58.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.63, 0.87, 0.42]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_59.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_59.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0, 0.02, 0]} rotation={[0, Math.PI / 2, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.leg_1.geometry}
          material={nodes.leg_1.material}
          position={[0.72, -0.42, 0.74]}
          rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
        />
      </group>
      <group position={[0.02, 0.02, 0]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.leg_2.geometry}
          material={nodes.leg_2.material}
          position={[0.72, -0.42, 0.74]}
          rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
        />
      </group>
      <group position={[0.02, -0.01, 0.04]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.leg_3.geometry}
          material={nodes.leg_3.material}
          position={[0.72, -0.42, 0.74]}
          rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
        />
      </group>
      <group position={[1.35, -0.78, 0.32]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_60.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_60.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.15, -1.02, 0.19]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_61.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_61.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.98, -1.29, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_62.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_62.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.77, -1.01, 0.2]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_63.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_63.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.41, -0.75, 0.45]} rotation={[0, -0.37, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_64.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_64.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.62, 0.69, 0.32]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_65.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_65.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.03, -0.76, 0.19]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_66.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_66.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.77, -0.73, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_67.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_67.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.46, 0.44, 0.34]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_68.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_68.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.36, 0.44, 0.66]} rotation={[0, -0.67, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_69.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_69.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.45, -0.1, 0.36]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_70.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_70.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.01, 0.06, 0.55]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_71.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_71.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.08, 0.93, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_72.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_72.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.66, 0.93, 0.84]} rotation={[0, -0.93, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_73.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_73.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.43, 0.64, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_74.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_74.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.84, 0.31, 0.14]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_75.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_75.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.92, 0.55, -0.33]} rotation={[0, 0.36, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_76.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_76.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.38, 0.35, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_77.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_77.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.73, -0.02, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_78.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_78.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.7, 0.16, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_79.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_79.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.37, -0.25, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_80.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_80.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.4, -0.49, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_81.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_81.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.24, -0.73, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_82.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_82.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.16, 0.24, -0.28]} rotation={[0, 0.5, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_83.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_83.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.16, 0.52, -0.55]} rotation={[0, 0.8, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_84.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_84.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.21, -0.42, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_85.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_85.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.62, -0.25, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_86.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_86.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.99, -0.51, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_87.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_87.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.89, -0.25, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_88.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_88.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.55, -0.31, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_89.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_89.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.44, -0.03, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_90.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_90.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.15, 0.31, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_91.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_91.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.1, 0.62, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_92.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_92.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.22, 0.69, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_93.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_93.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.22, 0.9, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_94.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_94.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.32, 0.05, -0.06]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_95.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_95.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.52, 0.29, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_96.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_96.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.1, -0.07, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_97.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_97.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.05, 0.53, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_98.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_98.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.82, 0.73, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_99.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_99.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.6, 0.51, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_100.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_100.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.84, 0.36, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_101.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_101.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.04, 0.19, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_102.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_102.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.64, 0.19, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_103.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_103.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.73, -0.38, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_104.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_104.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.69, -0.14, -0.19]} rotation={[0, 0.32, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_105.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_105.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.95, -1.02, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_106.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_106.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.41, -0.25, 0.12]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_107.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_107.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.4, -0.54, 0.12]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_108.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_108.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.86, -1.07, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_109.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_109.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.08, -0.28, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_110.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_110.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.34, 0.06, -0.15]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_1.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_1.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.13, -0.17, -0.13]} rotation={[0, -0.2, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_2.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_2.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.18, 0.67, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_3.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_3.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.65, 0.17, -0.18]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_4.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_4.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.72, 0.47, 0.09]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_5.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_5.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.46, 0.63, -0.12]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_6.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_6.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.56, 0.15, 0.09]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_7.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_7.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.9, 0.12, -0.04]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_8.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_8.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.67, 0.41, -0.11]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_9.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_9.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.25, -0.66, -0.3]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_10.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_10.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.36, 0.17, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_11.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_11.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.95, 0.37, -0.35]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_12.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_12.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.25, 0.96, -0.17]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_13.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_13.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.84, -0.91, -0.27]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_14.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_14.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.27, 0.41, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_15.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_15.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.13, -0.4, -0.02]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_16.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_16.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.48, 0.65, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_17.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_17.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.4, -0.41, -0.12]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_18.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_18.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.79, 0.68, -0.25]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_19.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_19.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.86, 0.68, -0.09]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_20.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_20.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.03, -0.9, -0.27]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_21.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_21.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.05, 0.8, -0.09]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_22.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_22.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.46, 1.03, -0.22]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_23.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_23.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.53, -0.92, -0.24]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_24.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_24.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.3, 0.43, 0.12]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_25.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_25.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.27, 0.09, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_26.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_26.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.08, 0.54, 0.05]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_27.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_27.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.22, -0.74, -0.19]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_28.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_28.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.86, -0.09, -0.26]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_29.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_29.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.33, -0.38, -0.33]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_30.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_30.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.64, -0.29, 0.08]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_31.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_31.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.14, -0.29, 0]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_32.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_32.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.07, 0.31, -0.89]} rotation={[-Math.PI, 1.17, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_111.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_111.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.7, 0.61, -0.65]} rotation={[-Math.PI, 0.74, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_112.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_112.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.95, 0.9, -1.02]} rotation={[-2.86, 0.86, 2.92]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_113.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_113.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.4, 1.14, -0.59]} rotation={[-Math.PI, 0.58, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_114.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_114.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.01, 1.19, -0.22]} rotation={[-Math.PI, 0.12, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_115.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_115.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.27, 1.23, -0.21]} rotation={[-3.1, 0, -3.14]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_116.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_116.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.51, 1.26, -0.98]} rotation={[-2.8, 0, -3.14]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_117.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_117.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1, 1.07, -0.73]} rotation={[-2.85, 0, -3.14]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_118.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_118.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-2.28, 0.83, 0.09]} rotation={[-2.97, -0.54, -3.05]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_119.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_119.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.23, 1.17, -0.28]} rotation={[-3.06, 0, -3.14]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_120.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_120.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.56, 1.21, -0.15]} rotation={[-2.94, -0.29, -3.08]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_121.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_121.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.69, 1.15, 0.28]} rotation={[Math.PI, -0.42, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_122.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_122.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-2.06, 1.09, 0.4]} rotation={[Math.PI, -0.47, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_123.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_123.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.96, 0.83, 0.29]} rotation={[-Math.PI, -0.5, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_124.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_124.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-2.15, 0.56, -0.19]} rotation={[Math.PI, -0.13, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_125.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_125.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.94, 0.32, 0.16]} rotation={[-Math.PI, -0.44, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_126.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_126.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.8, 0.08, 0.34]} rotation={[Math.PI, -0.54, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_127.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_127.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.5, -0.16, 0.39]} rotation={[Math.PI, -0.77, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_128.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_128.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.51, -0.5, -0.27]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_129.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_129.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.45, -0.48, 0.34]} rotation={[-Math.PI, -0.49, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_130.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_130.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.03, -0.5, 0.19]} rotation={[-Math.PI, -0.41, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_131.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_131.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.71, -0.48, -0.16]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_132.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_132.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.44, -0.48, -0.19]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_133.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_133.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.37, -0.47, -0.82]} rotation={[-Math.PI, 0.96, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_134.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_134.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.78, -0.25, -0.93]} rotation={[-Math.PI, 1.21, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_135.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_135.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.5, 0.22, -0.79]} rotation={[-Math.PI, 0.81, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_136.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_136.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.16, 0.65, -0.59]} rotation={[-Math.PI, 0.39, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_137.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_137.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.12, 0.92, -0.7]} rotation={[-Math.PI, 0.4, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_138.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_138.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.25, 0.9, -0.33]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_139.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_139.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.56, 0.98, -1.04]} rotation={[-2.91, 0.27, 3.08]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_140.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_140.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.89, 0.76, -0.7]} rotation={[-2.98, 0, -3.14]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_141.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_141.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.06, 0.92, 0.02]} rotation={[Math.PI, -0.44, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_142.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_142.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.39, 0.94, -0.23]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_143.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_143.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.66, 0.91, -0.23]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_144.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_144.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.84, 0.69, 0]} rotation={[-Math.PI, -0.27, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_145.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_145.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.7, 0.43, 0.11]} rotation={[-Math.PI, -0.53, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_146.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_146.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.56, 0.15, 0.1]} rotation={[-Math.PI, -0.52, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_147.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_147.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.51, -0.1, -0.39]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_148.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_148.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.15, -0.2, -0.38]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_149.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_149.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.85, -0.22, -0.39]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_150.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_150.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.55, -0.2, -0.23]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_151.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_151.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.15, -0.19, -0.75]} rotation={[-Math.PI, 0.5, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_152.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_152.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.06, 0.11, -0.74]} rotation={[-Math.PI, 0.54, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_153.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_153.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.08, 0.44, -0.29]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_154.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_154.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.22, 0.63, -0.41]} rotation={[-Math.PI, 0.11, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_155.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_155.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.67, 0.53, -0.42]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_156.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_156.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.07, 0.54, -0.44]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_157.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_157.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.35, 0.67, -0.06]} rotation={[-Math.PI, -0.4, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_158.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_158.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.56, 0.69, -0.31]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_159.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_159.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.5, 0.4, -0.19]} rotation={[Math.PI, -0.27, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_160.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_160.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.36, 0.12, -0.37]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_161.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_161.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.05, 0.09, -0.45]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_162.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_162.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.66, 0.12, -0.38]} rotation={[-Math.PI, 0.4, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_163.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_163.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.37, 0.12, -0.27]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_164.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_164.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.42, 0.38, -0.32]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_165.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_165.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.89, 0.36, -0.45]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_166.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_166.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.14, 0.32, -0.46]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_167.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_167.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.72, -0.01, -0.76]} rotation={[-Math.PI, 0.94, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_168.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_168.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.47, 0.87, -0.37]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_169.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_169.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.19, -0.78, -0.27]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_170.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_170.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.98, -1.02, -0.14]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_171.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_171.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.81, -1.29, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_172.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_172.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.6, -1.01, -0.16]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_173.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_173.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.25, -0.75, -0.4]} rotation={[-Math.PI, 0.37, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_174.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_174.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.46, 0.69, -0.27]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_175.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_175.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.86, -0.76, -0.14]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_176.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_176.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.6, -0.73, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_177.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_177.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.3, 0.44, -0.29]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_178.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_178.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.52, 0.44, -0.62]} rotation={[-Math.PI, 0.67, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_179.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_179.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.28, -0.1, -0.31]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_180.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_180.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.84, 0.06, -0.5]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_181.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_181.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.91, 0.93, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_182.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_182.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.82, 0.93, -0.79]} rotation={[-Math.PI, 0.92, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_183.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_183.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.27, 0.64, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_184.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_184.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.68, 0.31, -0.09]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_185.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_185.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.03, -0.05, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_186.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_186.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.76, 0.55, 0.38]} rotation={[-Math.PI, -0.36, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_187.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_187.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.21, 0.35, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_188.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_188.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.56, -0.02, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_189.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_189.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.53, 0.16, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_190.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_190.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.07, -0.25, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_191.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_191.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.23, -0.49, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_192.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_192.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.08, -0.73, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_193.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_193.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.99, 0.24, 0.33]} rotation={[-Math.PI, -0.5, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_194.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_194.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.99, 0.52, 0.6]} rotation={[Math.PI, -0.8, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_195.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_195.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.05, -0.42, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_196.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_196.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.45, -0.25, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_197.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_197.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.82, -0.51, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_198.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_198.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.73, -0.25, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_199.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_199.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.38, -0.31, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_200.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_200.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.28, -0.03, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_201.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_201.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.02, 0.31, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_202.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_202.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.26, 0.62, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_203.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_203.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.06, 0.69, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_204.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_204.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.06, 0.9, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_205.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_205.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.16, 0.05, 0.11]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_206.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_206.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.35, 0.29, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_207.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_207.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.94, -0.07, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_208.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_208.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.72, -0.15, 0.05]} rotation={[-Math.PI, -0.3, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_209.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_209.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.89, 0.53, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_210.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_210.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.66, 0.73, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_211.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_211.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.44, 0.51, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_212.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_212.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.68, 0.36, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_213.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_213.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.88, 0.19, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_214.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_214.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.48, 0.19, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_215.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_215.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.57, -0.38, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_216.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_216.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.53, -0.14, 0.21]} rotation={[-Math.PI, -0.32, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_217.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_217.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.29, 0.2, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_218.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_218.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.78, -1.02, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_219.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_219.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-1.25, -0.25, -0.07]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_220.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_220.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.23, -0.54, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_221.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_221.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.69, -1.07, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_222.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_222.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.92, -0.28, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines_223.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines_223.material}
          position={[-0.95, 2.38, -0.01]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.5, 0.06, 0.2]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_33.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_33.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.29, -0.17, 0.18]} rotation={[-Math.PI, 0.19, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_34.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_34.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.01, 0.67, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_35.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_35.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.82, 0.08, 0.37]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_36.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_36.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.89, 0.47, -0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_37.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_37.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.3, 0.63, 0.17]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_38.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_38.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.72, 0.15, -0.04]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_39.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_39.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.06, 0.12, 0.08]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_40.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_40.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.83, 0.41, 0.16]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_41.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_41.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.41, -0.66, 0.34]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_42.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_42.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.2, 0.17, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_43.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_43.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.12, 0.37, 0.39]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_44.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_44.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.08, 0.96, 0.22]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_45.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_45.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.01, -0.91, 0.31]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_46.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_46.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.44, 0.41, 0.04]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_47.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_47.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.03, -0.4, 0.15]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_48.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_48.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.64, 0.65, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_49.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_49.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.56, -0.41, 0.17]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_50.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_50.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.96, 0.68, 0.3]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_51.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_51.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.03, 0.68, 0.13]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_52.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_52.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.19, -0.9, 0.31]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_53.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_53.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.22, 0.8, 0.13]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_54.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_54.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.62, 1.03, 0.27]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_55.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_55.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.69, -0.92, 0.28]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_56.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_56.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.47, 0.43, -0.07]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_57.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_57.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.43, 0.09, 0.05]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_58.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_58.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.24, 0.54, -0.01]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_59.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_59.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.38, -0.74, 0.24]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_60.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_60.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[2.03, -0.09, 0.31]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_61.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_61.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[1.5, -0.38, 0.38]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_62.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_62.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.81, -0.29, -0.03]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_63.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_63.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-0.09, -0.16, 0.13]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_64.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_64.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[0.3, -0.29, 0.05]} rotation={[-Math.PI, 0, -Math.PI]}>
        <mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.Simple_Simple_GPレイヤー_Lines001_65.geometry}
          material={nodes.Simple_Simple_GPレイヤー_Lines001_65.material}
          position={[0.76, 2.46, 0.51]}
          rotation={[1.35, -0.04, -0.14]}
          scale={[0.01, 0.01, 0.01]}
        />
      </group>
      <group position={[-2.68, 0.94, -0.6]} rotation={[0.19, -0.14, -1.16]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_1.geometry}
          material={nodes.petal_1.material}
          position={pos1}
          rotation={rot1}
          scale={scale1}
          {...props1}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen1(!isOpen1); changeState(1);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen1(!isOpen1); changeState(1);}}
        />
      </group>
      <group position={[-2.7, 3.84, -0.76]} rotation={[0.06, -0.4, -2.39]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_2.geometry}
          material={nodes.petal_2.material}
          position={pos2}
          rotation={rot2}
          scale={scale2}
          {...props2}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen2(!isOpen2); changeState(2);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen2(!isOpen2); changeState(2);}}
        />
      </group>
      <group position={[0.26, 4.6, -0.63]} rotation={[-0.4, -0.28, 2.44]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_3.geometry}
          material={nodes.petal_3.material}
          position={pos3}
          rotation={rot3}
          scale={scale3}
          {...props3}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen3(!isOpen3); changeState(3);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen3(!isOpen3); changeState(3);}}
        />
      </group>
      <group position={[1.64, 1.5, 0.1]} rotation={[-0.36, 0.08, 0.98]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_4.geometry}
          material={nodes.petal_4.material}
          position={pos4}
          rotation={rot4}
          scale={scale4}
          {...props4}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen4(!isOpen4); changeState(4);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen4(!isOpen4); changeState(4);}}
        />
      </group>
      <group position={[1.08, -0.69, 0.64]} rotation={[-0.06, 0.18, 0]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_5.geometry}
          material={nodes.petal_5.material}
          position={pos5}
          rotation={rot5}
          scale={scale5}
          {...props5}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen5(!isOpen5); changeState(5);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen5(!isOpen5); changeState(5);}}
        />
      </group>
      <group position={[-1.35, 0.24, -0.03]} rotation={[0.25, -0.12, -1.09]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_6.geometry}
          material={nodes.petal_6.material}
          position={pos6}
          rotation={rot6}
          scale={scale6}
          {...props6}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen6(!isOpen6); changeState(6);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen6(!isOpen6); changeState(6);}}
        />
      </group>
      <group position={[-1.55, 2.81, -0.36]} rotation={[-0.11, -0.39, -2.34]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_7.geometry}
          material={nodes.petal_7.material}
          position={pos7}
          rotation={rot7}
          scale={scale7}
          {...props7}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen7(!isOpen7); changeState(7);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen7(!isOpen7); changeState(7);}}
        />
      </group>
      <group position={[1.34, 3.7, -0.49]} rotation={[-0.61, -0.29, 2.51]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_8.geometry}
          material={nodes.petal_8.material}
          position={pos8}
          rotation={rot8}
          scale={scale8}
          {...props8}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen8(!isOpen8); changeState(8);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen8(!isOpen8); changeState(8);}}
        />
      </group>
      <group position={[2.92, 1.1, 0.66]} rotation={[-0.38, 0.08, 1.08]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_9.geometry}
          material={nodes.petal_9.material}
          position={pos9}
          rotation={rot9}
          scale={scale9}
          {...props9}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen9(!isOpen9); changeState(9);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen9(!isOpen9); changeState(9);}}
        />
      </group>
      <group position={[0.26, 0.56, 0.61]} rotation={[0.12, 0.52, -0.62]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_10.geometry}
          material={nodes.petal_10.material}
          position={pos10}
          rotation={rot10}
          scale={scale10}
          {...props10}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen10(!isOpen10); changeState(10);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen10(!isOpen10); changeState(10);}}
        />
      </group>
      <group position={[-1.33, 2.34, 0.66]} rotation={[0.03, 0.09, -1.62]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_11.geometry}
          material={nodes.petal_11.material}
          position={pos11}
          rotation={rot11}
          scale={scale11}
          {...props11}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen11(!isOpen11); changeState(11);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen11(!isOpen11); changeState(11);}}
        />
      </group>
      <group position={[-0.22, 4.91, -0.22]} rotation={[-0.25, -0.1, -2.83]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_12.geometry}
          material={nodes.petal_12.material}
          position={pos12}
          rotation={rot12}
          scale={scale12}
          {...props12}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen12(!isOpen12); changeState(12);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen12(!isOpen12); changeState(12);}}
        />
      </group>
      <group position={[2.64, 4.74, -0.58]} rotation={[-0.35, 0.23, 2.19]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_13.geometry}
          material={nodes.petal_13.material}
          position={pos13}
          rotation={rot13}
          scale={scale13}
          {...props13}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen13(!isOpen13); changeState(13);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen13(!isOpen13); changeState(13);}}
        />
      </group>
      <group position={[3.01, 1.01, 0.56]} rotation={[-0.36, 0.3, 0.55]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_14.geometry}
          material={nodes.petal_14.material}
          position={pos14}
          rotation={rot14}
          scale={scale14}
          {...props14}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen14(!isOpen14); changeState(14);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen14(!isOpen14); changeState(14);}}
        />
      </group>
      <group position={[-1.17, 1.03, 0.93]} rotation={[-0.55, -0.46, -0.39]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_15.geometry}
          material={nodes.petal_15.material}
          position={pos15}
          rotation={rot15}
          scale={scale15}
          {...props15}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen15(!isOpen15); changeState(15);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen15(!isOpen15); changeState(15);}}
        />
      </group>
      <group position={[-2.72, 1.75, -1.17]} rotation={[-0.22, -0.56, -1.25]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_16.geometry}
          material={nodes.petal_16.material}
          position={pos16}
          rotation={rot16}
          scale={scale16}
          {...props16}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen16(!isOpen16); changeState(16);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen16(!isOpen16); changeState(16);}}
        />
      </group>
      <group position={[-1.83, 3.83, -2.53]} rotation={[-0.8, -0.89, -2.78]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_17.geometry}
          material={nodes.petal_17.material}
          position={pos17}
          rotation={rot17}
          scale={scale17}
          {...props17}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen17(!isOpen17); changeState(17);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen17(!isOpen17); changeState(17);}}
        />
      </group>
      <group position={[0.51, 4.9, -1.45]} rotation={[-1.24, -0.72, 2.06]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_18.geometry}
          material={nodes.petal_18.material}
          position={pos18}
          rotation={rot18}
          scale={scale18}
          {...props18}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen18(!isOpen18); changeState(18);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen18(!isOpen18); changeState(18);}}
        />
      </group>
      <group position={[1.06, 3.42, 1.47]} rotation={[-0.9, -0.54, 0.79]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_19.geometry}
          material={nodes.petal_19.material}
          position={pos19}
          rotation={rot19}
          scale={scale19}
          {...props19}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen19(!isOpen19); changeState(19);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen19(!isOpen19); changeState(19);}}
        />
      </group>
      <group position={[-0.03, 0.91, 0.54]} rotation={[-0.13, 0.38, -0.37]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_20.geometry}
          material={nodes.petal_20.material}
          position={pos20}
          rotation={rot20}
          scale={scale20}
          {...props20}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen20(!isOpen20); changeState(20);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen20(!isOpen20); changeState(20);}}
        />
      </group>
      <group position={[-2.04, 2.38, 0.2]} rotation={[-0.08, 0.01, -1.44]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_21.geometry}
          material={nodes.petal_21.material}
          position={pos21}
          rotation={rot21}
          scale={scale21}
          {...props21}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen21(!isOpen21); changeState(21);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen21(!isOpen21); changeState(21);}}
        />
      </group>
      <group position={[-1.66, 4.67, -0.78]} rotation={[-0.35, -0.19, -2.52]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_22.geometry}
          material={nodes.petal_22.material}
          position={pos22}
          rotation={rot22}
          scale={scale22}
          {...props22}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen22(!isOpen22); changeState(22);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen22(!isOpen22); changeState(22);}}
        />
      </group>
      <group position={[1.81, 4.9, -1.11]} rotation={[-0.77, -0.17, 2.27]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_23.geometry}
          material={nodes.petal_23.material}
          position={pos23}
          rotation={rot23}
          scale={scale23}
          {...props23}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen23(!isOpen23); changeState(23);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen23(!isOpen23); changeState(23);}}
        />
      </group>
      <group position={[2.33, 1.85, 0.2]} rotation={[-0.52, 0.32, 0.76]}>
        <animated.mesh
          castShadow
          /*receiveShadow*/
          geometry={nodes.petal_24.geometry}
          material={nodes.petal_24.material}
          position={pos24}
          rotation={rot24}
          scale={scale24}
          {...props24}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => {allowOpen(); closeAll(); setOpen24(!isOpen24); changeState(24);}}
          onPointerUp={() => {allowOpen(); closeAll(); setOpen24(!isOpen24); changeState(24);}}
        />
      </group>
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.円柱.geometry}
        material={materials['マテリアル.001']}
        position={[-0.08, 2.34, -0.35]}
        rotation={[-0.04, 0, 0.05]}
        scale={[0.01, 1.76, 0.01]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.leaf001.geometry}
        material={nodes.leaf001.material}
        position={[-0.3, 0.86, -0.02]}
        scale={[0.21, 0.21, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.stem001.geometry}
        material={nodes.stem001.material}
        position={[-0.3, 0.86, -0.02]}
        scale={[0.42, 0.22, 0.42]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.ベジエカーブ001.geometry}
        material={nodes.ベジエカーブ001.material}
        position={[-0.3, 0.86, -0.02]}
        scale={[0.21, 0.21, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.ベジエカーブ002.geometry}
        material={nodes.ベジエカーブ002.material}
        position={[0.4, 0.86, -0.02]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[0.21, 0.21, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.leg.geometry}
        material={nodes.leg.material}
        position={[0.72, -0.42, 0.74]}
        rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
      />
      <group position={[0, 0.41, 0.01]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.59, 0.59, 0.59]}>
        <mesh castShadow /*receiveShadow*/ geometry={nodes.Cube_1.geometry} material={materials.pinklight} />
        <mesh castShadow /*receiveShadow*/ geometry={nodes.Cube_2.geometry} material={materials.pinkvivid} />
        <mesh castShadow /*receiveShadow*/ geometry={nodes.Cube_3.geometry} material={materials.letterwhite} />
      </group>
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.Simple_Simple_GPレイヤー_Lines.geometry}
        material={nodes.Simple_Simple_GPレイヤー_Lines.material}
        position={[-0.95, 2.38, -0.01]}
        rotation={[1.35, -0.04, -0.14]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.leaf004.geometry}
        material={nodes.leaf004.material}
        position={[-0.48, 1.84, 0.53]}
        rotation={[-2.96, 0.26, 3.01]}
        scale={[0.11, 0.11, 0.11]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.stem004.geometry}
        material={nodes.stem004.material}
        position={[-0.36, 0.86, 0.38]}
        rotation={[-2.96, 0.26, 3.01]}
        scale={[0.21, 0.12, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.トーラス003.geometry}
        material={nodes.トーラス003.material}
        position={[-0.84, 2.51, 0.72]}
        rotation={[-1.61, -0.03, 2.86]}
        scale={[0.03, 0.02, 0.03]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.leaf003.geometry}
        material={nodes.leaf003.material}
        position={[0.17, 2.11, 0.59]}
        rotation={[-2.96, 0.26, 3.01]}
        scale={[0.11, 0.11, 0.11]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.stem003.geometry}
        material={nodes.stem003.material}
        position={[0.34, 0.86, 0.38]}
        rotation={[-2.96, 0.26, 3.01]}
        scale={[0.21, 0.18, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.トーラス002.geometry}
        material={nodes.トーラス002.material}
        position={[1.29, 3.05, 1.16]}
        rotation={[-1.73, -0.14, -2.82]}
        scale={[0.03, 0.02, 0.03]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.stem005.geometry}
        material={nodes.stem005.material}
        position={[0.15, 0.86, 0.38]}
        rotation={[-2.96, 0.26, 3.01]}
        scale={[0.21, 0.1, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.トーラス004.geometry}
        material={nodes.トーラス004.material}
        position={[0.38, 1.87, 1.34]}
        rotation={[-1.77, -0.08, 3.13]}
        scale={[0.03, 0.02, 0.03]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.stem006.geometry}
        material={nodes.stem006.material}
        position={[-0.33, 0.86, -0.4]}
        rotation={[-2.96, 0.26, 3.01]}
        scale={[0.21, 0.23, 0.22]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.トーラス005.geometry}
        material={nodes.トーラス005.material}
        position={[-1.34, 3.67, 0.17]}
        rotation={[-2, 0.39, 2.42]}
        scale={[0.03, 0.02, 0.03]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.stem007.geometry}
        material={nodes.stem007.material}
        position={[0.32, 0.86, -0.12]}
        rotation={[-2.96, 0.26, 3.01]}
        scale={[0.21, 0.21, 0.22]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.トーラス006.geometry}
        material={nodes.トーラス006.material}
        position={[0.34, 3.61, 0.8]}
        rotation={[-1.95, -0.13, -3]}
        scale={[0.03, 0.02, 0.03]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.テキスト.geometry}
        material={materials['マテリアル.004']}
        position={[-0.8, 4.22, -0.41]}
        rotation={[1.45, 0, 0]}
        scale={[3.13, 3.13, 3.13]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.ベジエ円.geometry}
        material={materials.a}
        position={[0, 0.42, 0.85]}
        scale={[1.24, 1.38, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.平面001.geometry}
        material={nodes.平面001.material}
        position={[0.04, 0.3, 1.15]}
        rotation={[-0.2, 0, 0]}
        scale={[1, 0.95, 0.45]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.トーラス001.geometry}
        material={nodes.トーラス001.material}
        position={[0.01, 1, 0.84]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.2, 0.49, 0.11]}
      />
      <animated.mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.petal.geometry}
        material={nodes.petal.material}
        position={pos0}
        rotation={rot0}
        scale={scale0}
        {...props0}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onClick={() => {allowOpen(); closeAll(); setOpen0(!isOpen0); changeState(0);}}
        onPointerUp={() => {allowOpen(); closeAll(); setOpen0(!isOpen0); changeState(0);}}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.Simple_Simple_GPレイヤー_Lines001.geometry}
        material={nodes.Simple_Simple_GPレイヤー_Lines001.material}
        position={[0.76, 2.46, 0.51]}
        rotation={[1.35, -0.04, -0.14]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.leaf002.geometry}
        material={nodes.leaf002.material}
        position={[0.4, 0.86, -0.02]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[0.21, 0.21, 0.21]}
      />
      <mesh
        castShadow
        /*receiveShadow*/
        geometry={nodes.stem002.geometry}
        material={nodes.stem002.material}
        position={[0.4, 0.86, -0.02]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[0.42, 0.22, 0.42]}
      />
    </group>
    );
  }
  useGLTF.preload('/rwbox06302.glb');

const UseModel = (prop) => {

    return (
        // ロード中の代替表示
        <Suspense fallback={null}>
            <Model changeState={prop.changeState} allowed={prop.allowed} allowOpen={prop.allowOpen} position={[-5, 0.7, 0.5]} rotation={[0, 0.5, 0]} castShadow />
        </Suspense>
    );
}

const Particle = () => {
  
  let group = useRef();
  let [rot, changeRot] = useState(0);

  useFrame(() => {
    changeRot(rot += 0.1);
    const r = 0.2 * THREE.Math.degToRad((rot));
    group.current.rotation.set(r, 1, 1);
  });

  const [geometry, material, vertices, coords] = useMemo(() => {
    const geometry = new THREE.SphereBufferGeometry(1, 10, 10);
    const material = new THREE.MeshBasicMaterial({ color: new THREE.Color('rgb(255, 255, 255)') });
    const coords = new Array(630).fill().map(i => [Math.random() * 800 - 400, Math.random() * 800 - 400, Math.random() * 800 - 400]);
    return [geometry, material, vertices, coords]
  }, [])

  return (
    <group ref={group}>
      {coords.map(([p1, p2, p3], i) => (
        <mesh key={i} geometry={geometry} material={material} position={[p1, p2, p3]} />
      ))}
    </group>
  )

}

export const Work = (props) => {
  
  const[windowSize, setWindowSize] = useState({width: undefined, height: undefined});
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    
    window.addEventListener("resize", handleResize);
    handleResize();
    
    return() => window.removeEventListener("resize", handleResize);
  }, []);

  const customPos = ((windowSize.width * 5 / 1161) - (2560 / 387));

  const [vrMode, setVrMode] = useState(false);
  useEffect(() => {
    if(window.navigator.userAgent.includes("Quest")) {
      setVrMode(true);
    }
  }, []);

  return(
    <div css={theme}>
        <Canvas vr={vrMode} gl={{antialias: true}} camera={{position: [-0.5, 2.5, 6.2]}} onCreated={({ gl }) => {
            gl.shadowMap.enabled = true
            gl.shadowMap.type = THREE.PCFShadowMap
            gl.setPixelRatio(window.devicePixelRatio)
            if(vrMode) {
              document.body.appendChild(VRButton.createButton(gl)).style.zIndex = 1
             }
        }}>  
            <ambientLight intensity={0.5} />
            <directionalLight position={[-3, 12, 4]} castShadow shadow-camera-near={0.1}  shadow-mapSize-height={512} shadow-mapSize-width={512} />
            <fog attach="fog" args={["white", 5, 40]} />
            <Controls minDistance={2} maxDistance={10} target={new THREE.Vector3(customPos, 3, 0)} />
            <UseModel changeState={props.changeState} allowOpen={props.allowOpen} allowed={props.allowed} />
            <Plane />
            <Particle />
            <XR>
              <DefaultXRControllers />
            </XR>
        </Canvas>
    </div>
  );
}