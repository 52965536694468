import React, { useEffect, createRef, useRef, DetailedHTMLProps, HtmlHTMLAttributes, HTMLAttributes } from "react";
import * as THREE from 'three';
import { Canvas, useFrame } from "@react-three/fiber";
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { Work } from '../work';

const globalStyles = css`
  ${emotionReset}
`;

const BaseScene = (props) => {
  return (
      <div>
        <Global styles={globalStyles} />
        <Work changeState={props.changeState} allowOpen={props.allowOpen} allowed={props.allowed} />
      </div>
  )
}
export default BaseScene
