import React from "react"
import Head from "./head"
import Div100vh from "react-div-100vh"

export default ({ children }) => (
  <>
    <Head />
    <link href="https://fonts.googleapis.com/css2?family=Bad+Script" rel="stylesheet" />
    <Div100vh style={{ margin: `0`, padding: `0` }}>
      {children}
    </Div100vh>
  </>
);