import React from 'react'
import Helmet from 'react-helmet'

const head = (props) => (
    <Helmet>
        <html lang="ja" />
        <title>A.I.Flower!! 2021</title>
        <meta charset="utf-8" />
        <meta name="description" content="キズナアイちゃんの5歳のお誕生日と「A.I.Party 2021」の開催をお祝いし、キズナーよりバーチャルフラスタを贈ります！" />
        <link rel="icon" type="image/svg+xml" href="/giftbox.svg" sizes="any" />
        <link rel="apple-touch-icon" sizes="180x180" href="https://aiflower2021.pages.dev/apple-touch-icon.png" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@UltraGreenCar" />

        <meta property="og:url" content="https://aiflower2021.pages.dev/" />
        <meta property="og:title" content="A.I.Flower!! 2021" />
        <meta property="og:description" content="キズナアイちゃんの5歳のお誕生日と「A.I.Party 2021」の開催をお祝いし、キズナーよりバーチャルフラスタを贈ります！" />
        <meta property="og:image" content="https://aiflower2021.pages.dev/ogpimage.png" />
        
    </Helmet>
)

export default head