import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button } from 'react-bootstrap'
import { useCallback } from 'react'

const Petal = (props) => {

    const mesData = props.mesData;

    const denyOpen = props.denyOpen;

    const petalRef = useRef();

    const scrollToTop = useCallback(() => {
        if(petalRef) {
            //@ts-ignore
             petalRef.current.scrollTo(0, 0);
        }
    }, [petalRef]);

    useEffect(() => {
        scrollToTop();
    });

    return (
        <div id="petalMan">
            <div id="petalTitle"><span style={{borderBottom: 2 + 'px dashed hotpink'}}>{mesData.node.name}</span> {mesData.node.twitter.length > 0 && <a href={mesData.node.twitter} target="_blank"><FontAwesomeIcon icon={faTwitter} style={{color: 'rgb(29, 161, 242)'}} /></a> }</div>
            <div id="petalText" ref={petalRef}>
                <p>{mesData.node.message.split('\n').map((str, index) => (<React.Fragment key={index}>{str}<br /></React.Fragment>))}</p>
                {mesData.node.youtube.length > 0 &&
                    <div className="koresuki">
                        <p><FontAwesomeIcon icon={faYoutube} style={{color: 'rgb(255, 0, 0)'}} /> <a href={mesData.node.youtube} target="_blank">{mesData.node.title}</a></p>
                        <p dangerouslySetInnerHTML={{__html : mesData.node.comment}} />
                    </div>
                }
                <Button style={{marginLeft: 2 + 'px'}} size="sm" onClick={() => {denyOpen(); props.changePetalOpen()}}>戻る</Button>
            </div>
        </div>
    );
};

export default Petal